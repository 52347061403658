<template>
  <div>
    <v-card flat>
      <v-toolbar flat color="transparent">
        <v-icon v-if="item &&
          item.locationDocumentSubtypes &&
          item.locationDocumentSubtypes.length > 0
          ">workspaces</v-icon>
        <v-toolbar-title v-if="type != 'subtype'">
          Manage Document
          <i style="font-size: 14px" v-if="item.id">{{ item.name }} (Version {{ item.version }})</i>
        </v-toolbar-title>
        <v-toolbar-title v-else>
          <v-icon class="mr-1" color="primary">subdirectory_arrow_right</v-icon>
          Manage Subtype Document
          <i style="font-size: 14px" v-if="item.id">{{ item.name }} (Version {{ item.version }})</i>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn v-if="type == 'new' || type == 'modify' || type == 'subtype'" text color="primary"
          :loading="savingDocument" :disabled="!item.name || !item.regulatoryBody"
          @click="saveDocument(item)">Save</v-btn>
        <v-btn text @click="close()">X</v-btn>
      </v-toolbar>
      <v-tabs v-if="type == 'new' || type == 'modify' || type == 'subtype'" class="mx-0 px-0"
        style="background-color: transparent" :key="tabKey">
        <v-tab v-for="tab in availableTabs" @click="selectedTab = tab" show-arrows :key="tab">{{
          tab != "Countries"
          ? tab
          : item.type == "Import"
            ? "Origin Countries"
            : "Destination Countries"
        }}</v-tab>
      </v-tabs>
      <v-card-text v-if="type == 'new' || type == 'modify' || type == 'subtype'" class="my-0">
        <div v-if="isTabSelected('Info')" class="mt-2">
          <v-row justify="center">
            <v-col cols="12" sm="12" md="12">
              <v-list class="text-center">
                <v-row justify="center" align="start">
                  <v-col cols="12" sm="12" md="6" class="my-0 py-0">
                    <v-autocomplete label="Regulatory Body*" :items="regulatoryBodies" item-value="id" item-text="name"
                      outlined dense v-model="item.regulatoryBody" return-object>
                      <template v-slot:item="data">
                        <v-list-item-avatar>
                          <v-img v-if="data.item.logo" contain :src="data.item.logo"></v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title v-html="data.item.name"></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" class="my-0 py-0">
                    <v-row>
                      <v-col cols="12" sm="6">
                        <v-text-field outlined dense label="Reference Code" v-model="item.reference"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <!-- <el-input-number :min="1" label="Rank" :value="item.rank" size="mini" v-model="item.rank"></el-input-number> -->
                        <v-text-field outlined type="number" dense label="Rank" v-model="item.rank"></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" class="my-0 py-0">
                    <v-text-field outlined dense label="Document Name*" v-model="item.name"></v-text-field>
                    <v-textarea label="Description" outlined auto-grow rows="3" v-model="item.description"></v-textarea>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" class="my-0 py-0">
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-select outlined dense item-text="name" item-value="value" label="Submission/Integration Type"
                          v-model="item.integrationType" :items="[
                            { name: 'Manual Template', value: 'manual' },
                            { name: 'REST API', value: 'restapi' },
                            // { name: 'SOAP API', value: 'soap' },
                            // { name: 'EDIFACT', value: 'edifact' },
                          ]"></v-select>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-list-item @click="selectCodeModal = true">
                          <v-list-item-action v-if="selectedCode">
                            <v-icon color="secondary">tag</v-icon>
                          </v-list-item-action>
                          <v-list-item-content class="text-center">
                            <v-list-item-title v-if="selectedCode">
                              {{ selectedCode }}
                            </v-list-item-title>
                            <span v-else style="font-size: 12px; color: grey">No code selected</span>
                          </v-list-item-content>
                          <v-list-item-action v-if="!selectedCode">
                            <v-icon color="orange">warning</v-icon>
                          </v-list-item-action>
                        </v-list-item>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-autocomplete label="Linked Task Type" item-text="name" item-value="id"
                          v-model="item.taskTypeId" outlined dense :items="taskTypes"></v-autocomplete>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-select label="Default Responsible Party" item-text="name" item-value="value"
                          v-model="item.responsiblePartyType" outlined dense :items="[
                            { name: 'Shipper', value: 'shipper' },
                            { name: 'Forwarder', value: 'forwarder' },
                            { name: 'Consignee', value: 'consignee' },
                            { name: 'Buyer', value: 'buyer' },
                          ]"></v-select>
                      </v-col>


                    </v-row>

                    <v-subheader>Who is allowed to see this document?</v-subheader>
                    <v-chip class="mx-1" :key="documentKey" @click="(item.shipper = !item.shipper), documentKey++"
                      outlined small :color="item.shipper ? 'green' : 'grey'">
                      <v-icon v-if="item.shipper" small color="green" class="mr-1">check</v-icon>
                      Shipper
                    </v-chip>
                    <v-chip class="mx-1" :key="documentKey" @click="(item.forwarder = !item.forwarder), documentKey++"
                      outlined small :color="item.forwarder ? 'green' : 'grey'">
                      <v-icon v-if="item.forwarder" small color="green" class="mr-1">check</v-icon>
                      Forwarder
                    </v-chip>
                    <v-chip class="mx-1" :key="documentKey" @click="(item.consignee = !item.consignee), documentKey++"
                      outlined small :color="item.consignee ? 'green' : 'grey'">
                      <v-icon v-if="item.consignee" small color="green" class="mr-1">check</v-icon>
                      Consignee
                    </v-chip>
                    <v-chip class="mx-1" :key="documentKey" @click="(item.buyer = !item.buyer), documentKey++" outlined
                      small :color="item.buyer ? 'green' : 'grey'">
                      <v-icon v-if="item.buyer" small color="green" class="mr-1">check</v-icon>
                      Buyer
                    </v-chip>
                    <v-chip class="mx-1" :key="documentKey" @click="(item.notifyParty = !item.notifyParty), documentKey++"
                      outlined small :color="item.notifyParty ? 'green' : 'grey'">
                      <v-icon v-if="item.notifyParty" small color="green" class="mr-1">check</v-icon>
                      Notify Party
                    </v-chip>
                    <v-checkbox label="Add document to courier pack" v-model="item.courier"></v-checkbox>

                  </v-col>
                  <v-col cols="12" sm="12" md="6" class="mt-0 pt-0">

                    <v-card v-if="item.integrationType == 'manual'" class="my-0 py-0 mx-0 px-0" flat>
                      <v-card-text class="my-0 py-0 mx-0 px-0">
                        <v-file-input v-if="!item.fileUpload && !item.file" @change="attachUpload" v-model="file" dense
                          outlined label="Upload manual template">
                        </v-file-input>
                        <v-list :key="fileKey" class="text-left">
                          <v-list-item v-if="item.fileUpload && item.fileUpload.name">
                            <v-list-item-action>
                              <v-img style="width: 30px; height: 30px" :src="getIcon(item.fileUpload.name)">
                              </v-img>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ item.fileUpload.name }}
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                {{ item.fileUpload.type }}
                              </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-btn icon color="red" @click="(item.fileUpload = null), fileKey++">
                                <v-icon>close</v-icon>
                              </v-btn>
                            </v-list-item-action>
                          </v-list-item>

                          <v-list-item v-else-if="typeof item.file == 'object' && item.fileName
                            ">
                            <v-list-item-action>
                              <v-img style="width: 30px; height: 30px" :src="getIcon(item.fileName)">
                              </v-img>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ item.fileName }}
                              </v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-btn icon color="red" @click="(item.fileUpload = null), fileKey++">
                                <v-icon>close</v-icon>
                              </v-btn>
                            </v-list-item-action>
                          </v-list-item>
                        </v-list>
                      </v-card-text>
                    </v-card>

                    <v-list-item v-if="item.file && typeof item.file == 'string'">
                      <v-list-item-action>
                        <v-img style="width: 30px; height: 30px" :src="getIcon(item.fileName)">
                        </v-img>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title class="text-left">
                          {{ item.fileName }}
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-row justify="center">
                          <v-btn icon @click="downloadDocument(item)"><v-icon>download</v-icon>
                          </v-btn>
                          <v-tooltip top v-if="item.integrationType == 'manual'">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn icon v-bind="attrs" v-on="on" @click="versionModal = true"
                                color="blue"><v-icon>add_task</v-icon></v-btn>
                            </template>
                            <span style="font-size: 12px">Add new version</span>
                          </v-tooltip>
                        </v-row>
                      </v-list-item-action>
                    </v-list-item>
                    <v-subheader>Quantity Type</v-subheader>
                    <v-btn-toggle class="my-0 py-0 mt-4" v-model="item.quantityType" mandatory>
                      <v-btn value="Shipment" style="text-transform: none">
                        <span>Per Shipment</span>
                      </v-btn>
                      <v-btn value="Container" style="text-transform: none">
                        <span>Per Container</span>
                      </v-btn>
                      <v-btn value="NotApplicable" style="text-transform: none">
                        <span>n/a</span>
                      </v-btn>
                    </v-btn-toggle>
                    <v-subheader>Document Type</v-subheader>
                    <v-btn-toggle class="my-0 py-0 mt-4" v-model="item.submissionType" mandatory>
                      <v-btn value="Digital" style="text-transform: none">
                        <span>Digital</span>
                      </v-btn>
                      <v-btn value="Physical" style="text-transform: none">
                        <span>Physical</span>
                      </v-btn>
                    </v-btn-toggle>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" class="mt-0 pt-0">
                    <v-list dense class="text-left">
                      <v-subheader style="font-size: 16px">Submission KPI Rules</v-subheader>
                      <v-row>
                        <v-col cols="12" sm="4">
                          <v-text-field label="No. days" outlined dense v-model="item.submittedRule"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4">
                          <v-select outlined dense label="Type" :items="['After', 'Before']"
                            v-model="item.submittedRuleType"> </v-select>
                        </v-col>
                        <v-col cols="12" sm="4">
                          <v-select label="Event" :items="[{ key: 'etd', value: 'ETD' }, { key: 'atd', value: 'ATD' },{ key: 'eta', value: 'ETA' }, { key: 'ata', value: 'ATA' }]"
                            item-text="value" item-value="key" outlined dense v-model="item.submittedRuleEvent">
                          </v-select>
                        </v-col>
                      </v-row>

                      <v-subheader style="font-size: 16px">Completed KPI Rules</v-subheader>
                      <v-row>
                        <v-col cols="12" sm="4">
                          <v-text-field label="No. days" outlined dense v-model="item.completedRule"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4">
                          <v-select outlined dense label="Type" :items="['After', 'Before']"
                            v-model="item.completedRuleType"> </v-select>
                        </v-col>
                        <v-col cols="12" sm="4">
                          <v-select label="Event" :items="[{ key: 'etd', value: 'ETD' }, { key: 'atd', value: 'ATD' },{ key: 'eta', value: 'ETA' }, { key: 'ata', value: 'ATA' }]"
                            item-text="value" item-value="key" outlined dense v-model="item.completedRuleEvent">
                          </v-select>
                        </v-col>
                      </v-row>

                    </v-list>

                  </v-col>
                </v-row>
              </v-list>
            </v-col>
          </v-row>
        </div>

        <div v-else-if="isTabSelected('Related Documents')">
          <v-row align="start" justify="start" :key="supportingDocumentKey">
            <v-col v-if="!item.parentTypeDocumentId && type != 'subtype'" cols="12" sm="12" md="6">
              <v-list subheader class="mt-0 pt-0">
                <v-subheader style="background: var(--v-component-base)">Variations <v-spacer></v-spacer><v-btn icon
                    color="primary"
                    @click="createDocumentSubtype()"><v-icon>add_circle_outline</v-icon></v-btn></v-subheader>
                <div v-if="item.locationDocumentSubtypes">
                  <draggable group="variations" v-model="item.locationDocumentSubtypes" @change="updateSubtypeRanks">
                    <v-list-item style="cursor: pointer" v-for="(
                        subtype, subtypeIndex
                      ) in item.locationDocumentSubtypes" :key="'subtype_' + subtypeIndex">
                      <v-list-item-action>
                        <v-row>
                          <v-chip class="mr-2">{{ subtype.rank }}</v-chip>
                          <v-tooltip top v-if="subtype.integrationType != 'manual'">
                            <template v-slot:activator="{ on, attrs }"><v-icon v-bind="attrs" v-on="on">api</v-icon>
                            </template>
                            <span style="font-size: 12px">Integration</span>
                          </v-tooltip>
                          <v-tooltip top v-else>
                            <template v-slot:activator="{ on, attrs }"><v-icon v-bind="attrs"
                                v-on="on">description</v-icon>
                            </template>
                            <span style="font-size: 12px">Manual Template</span>
                          </v-tooltip>
                        </v-row>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ subtype.name }}
                        </v-list-item-title>
                        <v-list-item-subtitle v-if="subtype.description" style="font-size: 12px" class="text-wrap">
                          {{ subtype.description }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-row justify="center">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn icon v-bind="attrs" v-on="on" @click="
                                editDocumentSubtype(subtype, subtypeIndex)
                                " small><v-icon small>edit</v-icon></v-btn>
                            </template>
                            <span style="font-size: 12px">Edit</span>
                          </v-tooltip>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn icon v-bind="attrs" v-on="on" @click="
                                deleteDocumentSubtype(subtype, subtypeIndex)
                                " color="red" small><v-icon small>delete</v-icon></v-btn>
                            </template>
                            <span style="font-size: 12px">Delete</span>
                          </v-tooltip>
                        </v-row>
                      </v-list-item-action>
                    </v-list-item>
                  </draggable>
                </div>

                <v-list-item v-if="type != 'subtype' &&
                  (!item.locationDocumentSubtypes ||
                    item.locationDocumentSubtypes.length == 0)
                  ">
                  <span style="color: grey">No documents.</span>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-list subheader class="mt-0 pt-0" :key="supportingDocumentKey">
                <v-subheader style="background: var(--v-component-base)">Classification Rules <v-spacer></v-spacer>
                  <v-btn icon color="primary"
                    @click="addSupportingDocument"><v-icon>add_circle_outline</v-icon></v-btn></v-subheader>
                <v-list-item v-for="(support, index) in item.locationSupportingDocuments" :key="support.id">
                  <v-list-item-content>
                    <v-list-item-title>{{
                      support.supportingDocument.name
                    }}</v-list-item-title>
                    <v-list-item-subtitle style="font-size: 12px" class="text-wrap">
                      <span v-for="label in support.supportingDocument
                        .documentLabels" :key="label.id">• {{ label.name }}</span>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-row justify="center">
                      <v-btn small icon color="red" @click="removeSupportingDoc(support, index)"><v-icon
                          small>delete</v-icon>
                      </v-btn>
                    </v-row>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item v-if="type != 'subtype' &&
                  (!item.locationSupportingDocuments ||
                    item.locationSupportingDocuments.length == 0)
                  ">
                  <span style="color: grey">No Classification Rules.</span>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </div>

        <!-- Area Tab -->
        <div v-else-if="isTabSelected('Production Areas')">
          <v-toolbar flat color="transparent">
            <v-spacer></v-spacer>
            <v-text-field outlined dense clearable prepend-inner-icon="search" v-model="searchArea"></v-text-field>
          </v-toolbar>
          <v-data-table @toggle-select-all="selectAllLocations" item-key="id"
            :footer-props="{ 'items-per-page-options': [5, 10] }" :items-per-page="5" show-select :items="subdivisions"
            :headers="areaHeaders" :search="searchArea" v-model="selectedAreas"></v-data-table>
        </div>

        <div v-else-if="isTabSelected('Countries')">
          <v-row justify="center">
            <v-col cols="12" sm="12" :md="item.type == 'Import' ? 4 : 6">
              <v-list dense subheader>
                <v-subheader style="background: var(--v-component-base); font-size: 16px">{{ item.type == "Import" ?
                  "Origin" : "Destination" }}
                  Regions
                  <v-spacer></v-spacer>
                  <v-switch color="success" class="mr-1" :label="`${item.regionMode}`" false-value="Exclude"
                    true-value="Include" :error="item.regionMode != 'Include'" v-model="item.regionMode"></v-switch>
                </v-subheader>
                <v-subheader>
                  <v-spacer></v-spacer>
                  <v-chip small outlined color="success" class="mr-1" v-if="!showOnlySelectedRegion"
                    @click="showOnlySelectedRegion = true">
                    <v-icon right class="mr-1" small>check</v-icon>
                    Show Selected</v-chip>
                  <v-chip small outlined color="primary" class="mr-1" v-if="showOnlySelectedRegion"
                    @click="showOnlySelectedRegion = false">
                    <v-icon right class="mr-1" small>list</v-icon>
                    Show All</v-chip>
                  <v-chip outlined color="blue" v-if="!item.allRegion" small
                    @click="(item.allRegion = true), (selectedRegions = [])">Select All</v-chip>
                  <v-chip outlined color="danger" v-else small
                    @click="(item.allRegion = false), (selectedRegions = [])">Deselect All</v-chip>
                </v-subheader>
                <v-text-field class="mt-2 px-2" prepend-inner-icon="search" outlined dense label="Search"
                  v-model="searchRegion" clearable></v-text-field>
                <v-list dense style="max-height: 35vh; overflow-y: auto" :key="countryKey">
                  <v-list-item v-for="region in filterRegions" :key="region.id">
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ region.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle style="font-size: 12px" v-if="region.countryRegions">
                        {{ region.countryRegions.length }} Countries
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action :key="countryKey">
                      <v-icon v-if="item.allRegion" color="success">check</v-icon>
                      <v-checkbox v-if="!item.allRegion" v-model="selectedRegions" color="primary"
                        :value="region.id"></v-checkbox>
                    </v-list-item-action>
                  </v-list-item>
                  <v-list-item v-if="filterRegions && filterRegions.length == 0">
                    <v-list-item-content>
                      <span style="color: grey">No regions found.</span>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-list>
            </v-col>
            <v-col cols="12" sm="12" :md="item.type == 'Import' ? 4 : 6">
              <v-list subheader dense :key="countryKey">
                <v-subheader style="background: var(--v-component-base); font-size: 16px">{{ item.type == "Import" ?
                  "Origin" : "Destination" }}
                  Countries
                  <v-spacer></v-spacer>
                  <v-switch color="success" class="mr-1" :label="`${item.countryMode}`" false-value="Exclude"
                    true-value="Include" :error="item.countryMode != 'Include'" v-model="item.countryMode"
                    style="font-size: 12px"></v-switch>
                </v-subheader>

                <v-subheader>
                  <v-spacer></v-spacer>
                  <v-chip small outlined color="success" class="mr-1" v-if="!showOnlySelectedCountry"
                    @click="showOnlySelectedCountry = true">
                    <v-icon right class="mr-1" small>check</v-icon>
                    Show Selected</v-chip>
                  <v-chip small outlined color="primary" class="mr-1" v-if="showOnlySelectedCountry"
                    @click="showOnlySelectedCountry = false">
                    <v-icon right class="mr-1" small>list</v-icon>
                    Show All</v-chip>
                  <v-chip v-if="!item.allCountry" small outlined color="blue"
                    @click="(item.allCountry = true), (selectedCountries = [])">Select All Countries
                  </v-chip>
                  <v-chip v-else small outlined color="danger"
                    @click="(item.allCountry = false), (selectedCountries = [])">Deselect All Countries</v-chip>
                </v-subheader>
                <v-row>
                  <v-col cols="8" class="mx-0 px-0">
                    <v-text-field class="mt-2 px-2" prepend-inner-icon="search" outlined dense label="Search"
                      v-model="searchCountry" clearable></v-text-field>
                  </v-col>
                  <v-col cols="4" class="mx-0 px-0">
                    <v-autocomplete clearable dense prepend-inner-icon="filter_alt" label="Filter" outlined class="mt-2"
                      v-model="filterRegion" :items="regions" item-text="name" item-value="id"></v-autocomplete>
                  </v-col>
                </v-row>
                <v-list dense style="height: 35vh; overflow-y: auto" class="mt-0 pt-0">
                  <v-list-item style="height: 25px" v-for="country in filterCountries" :key="country.id">
                    <v-list-item-avatar>
                      <v-img contain v-if="country.iso2"
                        :src="`https://cdn.loglive.io/flags/4x3/${country.iso2.toLowerCase()}.svg`"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ country.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ country.regionCode }}
                        <span v-if="regionSelected(country.regionCode)" style="color: var(--v-success-base)"><i>(Region
                            Selected)</i></span>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-checkbox v-if="!item.allCountry" v-model="selectedCountries" color="primary"
                        :value="country.id"></v-checkbox>
                      <v-icon v-else color="success">check</v-icon>
                    </v-list-item-action>
                  </v-list-item>
                  <v-list-item v-if="filterCountries && filterCountries.length == 0">
                    <v-list-item-content>
                      <span style="color: grey">No countries found.</span>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-list>
            </v-col>

            <v-col cols="12" sm="12" md="4" v-if="item.type == 'Import'">
              <v-list dense subheader :key="pointOfEntryKey">
                <v-subheader style="background: var(--v-component-base); font-size: 16px">Points of Entry
                  <v-spacer></v-spacer>
                  <v-switch color="success" class="mr-1" :label="`${item.pointOfEntryMode}`" false-value="Exclude"
                    true-value="Include" :error="item.pointOfEntryMode != 'Include'" v-model="item.pointOfEntryMode"
                    style="font-size: 12px"></v-switch>
                </v-subheader>
                <v-row class="px-2">
                  <v-col cols="8" class="mx-0 px-0">
                    <v-autocomplete hide-no-data dense class="mt-2" outlined clearable :loading="entryPointLoading"
                      return-object :search-input.sync="entryPointSearch" v-model="specificPort"
                      @change="addPointOfEntry(specificPort)" :menu-props="{ closeOnContentClick: true }"
                      label="Search Entry Point" :items="entryPoints" item-text="name" item-value="locode">
                      <template v-slot:selection="data">
                        <span style="font-size: 12px">{{ data.item.name }} ({{ data.item.locode }})</span>
                      </template>
                      <template v-slot:item="data">
                        <v-list-item-content>
                          <v-list-item-title>{{
                            data.item.name
                          }}</v-list-item-title>
                          <v-list-item-subtitle>
                            {{ data.item.locode }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="4" class="mx-0 px-0">
                    <v-select dense label="Filter" outlined class="mt-2" v-model="filterPoints" :items="points"
                      item-text="name" item-value="value"></v-select>
                  </v-col>
                </v-row>

                <v-list-item style="height: 20px" v-for="point in item.locationDocumentPoints" :key="point.locode">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ point.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ point.locode }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn icon @click="removePointOfEntry(point)" color="red">
                      <v-icon>close</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </div>

        <!-- Products -->
        <div v-else-if="isTabSelected('Products')">
          <!-- <v-row justify="center">
            <v-col cols="12" sm="6">
              <v-subheader style="background: var(--v-component-base); font-size: 16px">
                  Products
                  <v-spacer></v-spacer>
                  <v-switch color="success" class="mr-1" :label="`All Product`" false-value="Exclude"
                    true-value="Include" :error="item.productMode != 'Include'" v-model="item.productMode"
                    style="font-size: 12px"></v-switch>
                </v-subheader>
            </v-col>
            <v-col cols="12" sm="6">

            </v-col>
          </v-row> -->
          <v-row justify="center">
            <v-col cols="12" sm="4">
              <v-list subheader dense :key="productKey">
                <v-subheader style="background: var(--v-component-base); font-size: 16px">
                  Product Groups
                  <v-spacer></v-spacer>
                  <v-switch color="success" class="mr-1" :label="`${item.productGroupMode}`" false-value="Exclude"
                    true-value="Include" :error="item.productGroupMode != 'Include'" v-model="item.productGroupMode"
                    style="font-size: 12px"></v-switch>
                </v-subheader>

                <v-subheader>
                  <v-spacer></v-spacer>
                  <v-chip small outlined color="success" class="mr-1"
                    v-if="!showOnlySelectedProductGroup && !item.allProductGroup"
                    @click="showOnlySelectedProductGroup = true">
                    <v-icon right class="mr-1" small>check</v-icon>
                    Show Selected</v-chip>
                  <v-chip small outlined color="primary" class="mr-1"
                    v-if="showOnlySelectedProductGroup && !item.allProductGroup"
                    @click="showOnlySelectedProductGroup = false">
                    <v-icon right class="mr-1" small>list</v-icon>
                    Show All</v-chip>
                  <v-chip v-if="!item.allProductGroup" small outlined color="blue" @click="
                    (item.allProductGroup = true),
                    (selectedProductGroups = [])
                    ">Select All Groups
                  </v-chip>
                  <v-chip v-else small outlined color="danger" @click="
                    (item.allProductGroup = false),
                    (selectedProductGroups = [])
                    ">Deselect All Groups</v-chip>
                </v-subheader>
                <v-row>
                  <v-col cols="12" class="mx-0 px-0">
                    <v-text-field class="mt-2 px-2" prepend-inner-icon="search" outlined dense label="Search"
                      v-model="searchProductGroup" clearable></v-text-field>
                  </v-col>
                </v-row>
                <v-list dense style="height: 42vh; overflow-y: auto" class="mt-0 pt-0">
                  <v-list-item style="height: 25px" v-for="group in filterProductGroups" :key="group.id">
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ group.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle v-if="group.hsCode">
                        {{ group.hsCode.alternateCode }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-checkbox v-if="!item.allProductGroup" v-model="selectedProductGroups" color="primary"
                        :value="group.id"></v-checkbox>
                      <v-icon v-else color="success">check</v-icon>
                    </v-list-item-action>
                  </v-list-item>
                  <v-list-item v-if="filterProductGroups && filterProductGroups.length == 0
                    ">
                    <v-list-item-content>
                      <span style="color: grey">No product groups found.</span>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-list>
            </v-col>

            <v-col cols="12" sm="4">
              <v-list subheader dense :key="productKey">
                <v-subheader style="background: var(--v-component-base); font-size: 16px">
                  Products
                  <v-spacer></v-spacer>
                  <v-switch color="success" class="mr-1" :label="`${item.productMode}`" false-value="Exclude"
                    true-value="Include" :error="item.productMode != 'Include'" v-model="item.productMode"
                    style="font-size: 12px"></v-switch>
                </v-subheader>

                <v-subheader>
                  <v-spacer></v-spacer>
                  <v-chip small outlined color="success" class="mr-1" v-if="!showOnlySelectedProduct && !item.allProduct"
                    @click="showOnlySelectedProduct = true">
                    <v-icon right class="mr-1" small>check</v-icon>
                    Show Selected</v-chip>
                  <v-chip small outlined color="primary" class="mr-1" v-if="showOnlySelectedProduct && !item.allProduct"
                    @click="showOnlySelectedProduct = false">
                    <v-icon right class="mr-1" small>list</v-icon>
                    Show All</v-chip>
                  <v-chip v-if="!item.allProduct" small outlined color="blue"
                    @click="(item.allProduct = true), (selectedProducts = [])">Select All Products
                  </v-chip>
                  <v-chip v-else small outlined color="danger"
                    @click="(item.allProduct = false), (selectedProducts = [])">Deselect All Products</v-chip>
                </v-subheader>
                <v-row>
                  <v-col cols="12" sm="6" class="mx-0 px-0">
                    <v-text-field class="mt-2 px-2 mb-0 pb-0" prepend-inner-icon="search" outlined dense label="Search"
                      v-model="searchProduct" clearable></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" class="mx-0 px-0">
                    <v-autocomplete clearable dense class="mt-2 py-0 px-2" prepend-inner-icon="filter_alt" label="Filter"
                      outlined v-model="productFilter" :items="products.data.filter((x) => x.type == 'Product Group')
                        " item-text="name" item-value="id"></v-autocomplete>
                  </v-col>
                </v-row>
                <v-list dense style="height: 42vh; overflow-y: auto" class="mt-0 pt-0">
                  <v-list-item v-for="product in filterProducts" :key="product.id">
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ product.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle v-if="product.parentProduct" style="font-size: 12px">
                        {{ product.parentProduct.name }}
                        <span v-if="productGroupSelected(product.productGroupId)"
                          style="color: var(--v-success-base); font-size: 12px"><i>(Selected)</i></span>
                      </v-list-item-subtitle>
                      <v-list-item-subtitle v-if="product.hsCode" style="font-size: 12px">
                        {{ product.hsCode.alternateCode }}
                      </v-list-item-subtitle>

                    </v-list-item-content>
                    <v-list-item-action>
                      <v-checkbox v-if="!item.allProduct" v-model="selectedProducts" color="primary"
                        :value="product.id"></v-checkbox>
                      <v-icon v-else color="success">check</v-icon>
                    </v-list-item-action>
                  </v-list-item>
                  <v-list-item v-if="filterProducts && filterProducts.length == 0">
                    <v-list-item-content>
                      <span style="color: grey">No products found.</span>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-list>
            </v-col>

            <v-col cols="12" sm="4">
              <v-list subheader dense :key="productKey">
                <v-subheader style="background: var(--v-component-base); font-size: 16px">
                  Varieties
                  <v-spacer></v-spacer>
                  <v-switch color="success" class="mr-1" :label="`${item.varietyMode}`" false-value="Exclude"
                    true-value="Include" :error="item.varietyMode != 'Include'" v-model="item.varietyMode"
                    style="font-size: 12px"></v-switch>
                </v-subheader>

                <v-subheader>
                  <v-spacer></v-spacer>
                  <v-chip small outlined color="success" class="mr-1" v-if="!showOnlySelectedVariety"
                    @click="showOnlySelectedVariety = true">
                    <v-icon right class="mr-1" small>check</v-icon>
                    Show Selected</v-chip>
                  <v-chip small outlined color="primary" class="mr-1" v-if="showOnlySelectedVariety"
                    @click="showOnlySelectedVariety = false">
                    <v-icon right class="mr-1" small>list</v-icon>
                    Show All</v-chip>
                  <v-chip v-if="!item.allVariety" small outlined color="blue"
                    @click="(item.allVariety = true), (selectedVarieties = [])">Select All Varieties
                  </v-chip>
                  <v-chip v-else small outlined color="danger"
                    @click="(item.allVariety = false), (selectedVarieties = [])">Deselect All Varieties</v-chip>
                </v-subheader>
                <v-row>
                  <v-col cols="12" sm="6" class="mx-0 px-0">
                    <v-text-field class="mt-2 px-2 mb-0 pb-0" prepend-inner-icon="search" outlined dense label="Search"
                      v-model="searchVariety" clearable></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" class="mx-0 px-0">
                    <v-autocomplete clearable dense class="mt-2 py-0 px-2" prepend-inner-icon="filter_alt" label="Filter"
                      outlined v-model="varietyFilter" :items="products.data.filter((x) => x.type == 'Product')"
                      item-text="name" item-value="id"></v-autocomplete>
                  </v-col>
                </v-row>
                <v-virtual-scroll :bench="0" v-if="filterVarieties.length > 0" :items="filterVarieties"
                  style="height: 42vh; overflow-y: auto" height="500" item-height="60">
                  <template v-slot:default="{ item }">
                    <v-list-item :key="item.id">
                      <v-list-item-content>
                        <v-list-item-title class="primaryText--text">
                          {{ item.name }}
                        </v-list-item-title>
                        <v-list-item-subtitle v-if="item.parentProduct" style="font-size: 12px">
                          {{ item.parentProduct.name }}
                          <span v-if="productSelected(item.productId)"
                            style="color: var(--v-success-base); font-size: 12px"><i>(Selected)</i></span>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle v-if="item.hsCode" style="font-size: 12px">
                          {{ item.hsCode.alternateCode }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-checkbox v-if="!allVarietySelected" v-model="selectedVarieties" color="primary"
                          :value="item.id"></v-checkbox>
                        <v-icon v-else color="success">check</v-icon>
                      </v-list-item-action>
                    </v-list-item>
                  </template>
                </v-virtual-scroll>
                <v-list-item v-if="filterVarieties && filterVarieties.length == 0">
                  <v-list-item-content>
                    <span style="color: grey">No varieties found.</span>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>

          </v-row>
        </div>
        <div v-else-if="isTabSelected('Attest Documents')">
          <v-row>
            <v-col cols="12" sm="6">
              <v-list subheader dense :key="countryKey">
                <v-subheader style="background: var(--v-component-base); font-size: 16px">
                  Attest Country Requirements
                </v-subheader>

                <v-subheader>
                  <v-spacer></v-spacer>
                  <v-chip small outlined color="success" class="mr-1" v-if="!showOnlySelectedCountryAttest"
                    @click="showOnlySelectedCountryAttest = true, countryKey++">
                    <v-icon right class="mr-1" small>check</v-icon>
                    Show Selected</v-chip>
                  <v-chip small outlined color="primary" class="mr-1" v-if="showOnlySelectedCountryAttest"
                    @click="showOnlySelectedCountryAttest = false, countryKey++">
                    <v-icon right class="mr-1" small>list</v-icon>
                    Show All</v-chip>
                  <v-chip v-if="!item.allCountryAttest" small outlined color="blue"
                    @click="(item.allCountryAttest = true), (selectedCountryAttest = []), countryKey++">Select All
                    Countries
                  </v-chip>
                  <v-chip v-else small outlined color="danger"
                    @click="(item.allCountryAttest = false), (selectedCountryAttest = []), countryKey++">Deselect All
                    Countries</v-chip>
                </v-subheader>
                <v-row>
                  <v-col cols="12" class="mx-0 px-0">
                    <v-text-field class="mt-2 px-2" prepend-inner-icon="search" outlined dense label="Search"
                      v-model="searchCountryAttest" clearable></v-text-field>
                  </v-col>
                </v-row>
                <v-list dense style="height: 35vh; overflow-y: auto" class="mt-0 pt-0">
                  <v-list-item style="height: 25px" v-for="country in filterCountryAttest" :key="country.id">
                    <v-list-item-avatar>
                      <v-img contain v-if="country.iso2"
                        :src="`https://cdn.loglive.io/flags/4x3/${country.iso2.toLowerCase()}.svg`"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ country.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ country.regionCode }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-checkbox v-if="!item.allCountryAttest" v-model="selectedCountryAttest" color="primary"
                        :value="country.id"></v-checkbox>
                      <v-icon v-else color="success">check</v-icon>
                    </v-list-item-action>
                  </v-list-item>
                  <v-list-item v-if="filterCountryAttest && filterCountryAttest.length == 0">
                    <v-list-item-content>
                      <span style="color: grey">No countries found.</span>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-list>
            </v-col>
            <v-col cols="12" sm="6">
              <v-autocomplete label="Attest Regulatory Body" :items="regulatoryBodies" item-value="id" item-text="name"
                outlined dense v-model="item.attestRegulatoryBodyId">
                <template v-slot:item="data">
                  <v-list-item-avatar>
                    <v-img v-if="data.item.logo" contain :src="data.item.logo"></v-img>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title v-html="data.item.name"></v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
              <!-- <v-autocomplete :item="regulatoryBodies" v-model="item.attestRegulatoryBodyId" item-text="name" item-value="id" label="Attest Regulatory Body" outlined dense></v-autocomplete> -->
            </v-col>
          </v-row>

        </div>
      </v-card-text>
    </v-card>

    <v-dialog v-model="versionModal" width="600px" persistent :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>New Version</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="versionModal = false">X</v-btn>
        </v-toolbar>

        <v-card-text class="text-center">
          <v-file-input v-if="!item.fileUpload" @change="attachUpload" v-model="file" dense outlined
            label="Upload manual template">
          </v-file-input>
          <v-list :key="fileKey" v-if="item.fileUpload && item.fileUpload.name" class="text-left">
            <v-list-item>
              <v-list-item-action>
                <v-img style="width: 30px; height: 30px" :src="getIcon(item.fileUpload.name)">
                </v-img>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.fileUpload.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ item.fileUpload.type }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn icon color="red" @click="(item.fileUpload = null), fileKey++">
                  <v-icon>close</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <v-row class="mt-2" justify="center">
            <v-btn text color="primary" :disabled="!item.fileUpload" :loading="submittingVersion"
              @click="submitVersion">Submit</v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Subtype Modal -->
    <v-dialog v-model="subtypeModal" persistent width="1200px" :fullscreen="$vuetify.breakpoint.mobile">
      <LocationDocumentModal :item="subtypeItem" :country="country" :products="products" :regions="regions"
        :subdivisions="subdivisions" :supportingDocuments="supportingDocuments" :regulatoryBodies="regulatoryBodies"
        :type="'subtype'" @update="updatedDocument" @updateFile="fileUpdated" :integrationOptions="integrationOptions"
        @close="(subtypeModal = false), (subtypeItem = {})" />
    </v-dialog>

    <v-dialog v-model="supportingDocumentModal" persistent width="400px" :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title> Manage Supporting Doc </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="
            (supportingDocumentModal = false), (supportingDocumentItem = {})
            ">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-autocomplete outlined dense prepend-inner-icon="search" label="Find Supporting Doc"
            :items="supportingDocuments" item-text="name" item-value="id" v-model="supportingDocumentItem" return-object>
          </v-autocomplete>
          <v-list v-if="supportingDocumentItem && supportingDocumentItem.id" style="max-height: 30vh; overflow-y: auto">
            <v-subheader>Document Labels</v-subheader>
            <v-list-item v-for="label in supportingDocumentItem.documentLabels" :key="label.id">
              <v-list-item-content>
                <v-list-item-title>
                  {{ label.name }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-row justify="center">
            <v-btn @click="saveLocationSupportingDocument" text color="primary"
              :disabled="!supportingDocumentItem || !supportingDocumentItem.id">Submit</v-btn>
          </v-row>
        </v-card-text>
      </v-card></v-dialog>

      <v-dialog v-model="selectCodeModal" width="400px" :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>
            Select Code
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="selectCodeModal=false">X</v-btn>
        </v-toolbar>
        <v-card-text style="max-height: 60vh; overflow-y:auto">
          <v-list dense v-for="(integration, index) in filteredIntegrationOptions" :key="'integration_' + index">
            <v-divider></v-divider>
            <v-subheader style="font-size: 16px"><v-icon class="mr-1">folder</v-icon> {{ integration.folder }}</v-subheader>
            <v-divider></v-divider>
            <v-list dense v-for="(provider, providerIndex) in integration.available" :key="'provider_' + providerIndex">
              <v-list-item >
                <v-list-item-action>
                  <v-icon color="secondary">category</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ provider.name }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-for="(method, methodIndex) in provider.methods" :key="'method_' + methodIndex" @click="setCode(method)">
                <v-list-item-content>
                  <v-list-item-title>
                    {{ method.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ method.description }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-list>
          <v-list-item></v-list-item>
        </v-card-text>
      </v-card>
      </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import draggable from "vuedraggable";
import LocationDocumentModal from "./LocationDocumentModal.vue";
export default {
  name: "LocationDocumentModal",
  props: [
    "item",
    "index",
    "country",
    "products",
    "regions",
    "region",
    "subdivisions",
    "supportingDocuments",
    "regulatoryBodies",
    "type",
    "integrationOptions"
  ],
  components: {
    draggable,
    LocationDocumentModal: LocationDocumentModal,
  },
  data: () => ({
    areaHeaders: [
      {
        text: "Code",
        value: "code",
        align: "center",
      },
      {
        text: "Name",
        value: "name",
        align: "center",
      },
      {
        text: "Area Type",
        value: "type",
        align: "center",
      },
    ],
    countries: [],
    countryKey: 0,
    document: {},
    documentLabels: [],
    documentLabel: {},
    documentKey: 0,
    entryPointLoading: false,
    entryPointSearch: null,
    entryPointTimeout: null,
    entryPoints: [],
    filterPoints: "port",
    label: null,
    filterProductGroup: null,
    filterProductType: null,
    filterRegion: null,
    fileList: [],
    file: null,
    fileKey: 0,
    labelList: 0,
    loadingProducts: false,
    loadingSupportingDocument: false,
    newLabel: null,
    modalType: "Add",
    points: [
      { name: "Airport", value: "airport" },
      { name: "Port", value: "port" },
      { name: "Rail", value: "rail" },
    ],
    pointOfEntryKey: 0,
    // products: { total: 0, data: [] },
    productHeaders: [
      {
        text: "",
        value: "selected",
        align: "center",
        width: "50px",
      },
      {
        text: "Name",
        value: "name",
        align: "center",
      },
      {
        text: "Type",
        value: "type",
        align: "center",
      },
      {
        text: "HS Code",
        value: "hsCode.alternateCode",
        align: "center",
      },
    ],
    productFilter: null,
    varietyFilter: null,
    productKey: 0,
    regionKey: 0,
    savingDocument: false,
    supportingDocumentList: [],
    supportingDocumentSearch: null,
    supportingDocumentTimeout: null,
    searchCountry: null,
    searchProduct: null,
    searchProductGroup: null,

    searchVariety: null,
    searchRegion: null,
    selectedAreas: [],
    selectCodeModal: false,
    selectedProducts: [],
    selectedProductGroups: [],
    selectedVarieties: [],
    selectedRegion: null,
    selectedCountries: [],
    selectedCountryAttest: [],
    selectedRegions: [],
    selectedTab: "Info",
    searchArea: null,
    searchCountryAttest: null,
    selectedSupportingDocument: null,
    selectedProductGroup: null,
    showOnlySelectedRegion: false,
    showOnlySelectedCountry: false,
    showOnlySelectedProductGroup: false,
    showOnlySelectedProduct: false,
    showOnlySelectedCountryAttest: false,
    showOnlySelectedVariety: false,

    specificPort: null,
    subtypeModal: false,
    subtypeItem: {},
    supportingDocumentItem: {},
    supportingDocumentModal: false,
    supportingDocumentKey: 0,
    submittingVersion: false,
    subtypeKey: 0,
    tabs: [
      "Info",
      // { key: 1, value: "Document Subtypes" },
      "Related Documents",
      "Production Areas",
      "Countries",
      "Products",
      "Attest Documents"
    ],
    tabKey: 0,
    taskTypes: [],
    versionModal: false,
  }),
  watch: {
    "item.id": {
      immediate: true,
      handler(val) {
        // if (val) {
        if (this.item.locationDocumentSubdivisions) {
          this.selectedAreas = this.subdivisions.filter((y) =>
            this.item.locationDocumentSubdivisions
              .map((x) => x.countrySubdivisionId)
              .includes(y.id)
          );
        } else {
          this.selectedAreas = this.subdivisions;
        }
        if (this.item.locationDocumentProducts) {
          this.selectedProducts = this.item.locationDocumentProducts
            .filter((x) => x.productId)
            .map((x) => x.productId);
          this.selectedProductGroups = this.item.locationDocumentProducts
            .filter((x) => x.productGroupId)
            .map((x) => x.productGroupId);
          this.selectedVarieties = this.item.locationDocumentProducts
            .filter((x) => x.varietyId)
            .map((x) => x.varietyId);
        } else {
          this.selectedProducts = [];
          this.selectedProductGroups = [];
          this.selectedVarieties = [];
        }
        if (this.item.locationDocumentAreas) {
          this.selectedRegions = this.item.locationDocumentAreas
            .filter((x) => x.regionId)
            .map((x) => x.regionId);

          this.selectedCountries = this.item.locationDocumentAreas
            .filter((x) => x.countryRegionId)
            .map((x) => x.countryRegionId);
        }

        if (this.item.locationDocumentAttests) {
          this.selectedCountryAttest = this.item.locationDocumentAttests
            .filter((x) => x.countryRegionId)
            .map((x) => x.countryRegionId);
        }

        // } else {
        //   // this.selectedAreas = this.subdivisions;
        //   // this.selectedProducts = [];
        //   // this.selectedProductGroups = [];
        //   // this.selectedCountries = [];
        // }
      },
    },
    item(val) {
      if (val && val.type) {
        if (this.type == "subtype") {
          this.selectedAreas = this.subdivisions.filter((y) =>
            val.locationDocumentSubdivisions
              .map((x) => x.countrySubdivisionId)
              .includes(y.id)
          );
          this.selectedProducts = val.locationDocumentProducts
            .filter((x) => x.productId)
            .map((x) => x.productId);
          this.selectedProductGroups = val.locationDocumentProducts
            .filter((x) => x.productGroupId)
            .map((x) => x.productGroupId);
          if (val.locationDocumentAreas) {
            this.selectedRegions = val.locationDocumentAreas
              .filter((x) => x.regionId)
              .map((x) => x.regionId);

            this.selectedCountries = val.locationDocumentAreas
              .filter((x) => x.countryRegionId)
              .map((x) => x.countryRegionId);
          }
        }
      }
    },
    async entryPointSearch(val) {
      if (this.entryPointLoading) {
        clearTimeout(this.entryPointTimeout);
      }
      if (val && val.length > 2) {
        this.entryPointLoading = true;

        this.entryPointTimeout = setTimeout(async () => {
          this.entryPoints = await this.$API.getCountryTowns(
            this.country.iso2,
            {
              params: {
                search: val,
                portType: this.filterPoints,
              },
            }
          );
          this.entryPointLoading = false;
        }, 250);
      } else {
        clearTimeout(this.entryPointTimeout);
        this.entryPointLoading = false;
      }
    },
    "item.allProductGroup": {
      immediate: true,
      handler() {
        this.productKey++;
      },
    },
    "item.allProduct": {
      immediate: true,
      handler() {
        this.productKey++;
      },
    },
    "item.allRegion": {
      immediate: true,
      handler() {
        this.regionKey++;
        this.countryKey++;
      },
    },
    "item.allCountry": {
      immediate: true,
      handler() {
        this.regionKey++;
        this.countryKey++;
      },
    },
    "item.locationSupportingDocuments": {
      immediate: true,
      handler(val) {
        if (val && val.length > 0) {
          if (!this.supportingDocumentItem) {
            this.editSupportingDocument(val[0]);
          } else if (
            !val.map((x) => x.id).includes(this.supportingDocumentItem.id)
          ) {
            this.editSupportingDocument(val[0]);

            // this.supportingDocumentItem = val[0];
          }
          this.supportingDocumentKey++;
        }
      },
    },
    async supportingDocumentSearch(val) {
      this.supportingDocumentList = [];
      if (this.loadingSupportingDocument) {
        clearTimeout(this.supportingDocumentTimeout);
      }
      if (val) {
        this.loadingSupportingDocument = true;
        this.supportingDocumentTimeout = setTimeout(async () => {
          this.supportingDocumentList =
            await this.$API.searchSupportingDocuments({
              params: {
                search: val,
              },
            });
          this.loadingSupportingDocument = false;
        }, 500);
      } else {
        clearTimeout(this.supportingDocumentTimeout);
        this.loadingSupportingDocument = false;
      }
    },

    "selectedSupportingDocument.name": {
      immediate: true,
      handler(val) {
        if (val) {
          this.supportingDocumentItem.name = val;
        }
      },
    },
  },
  computed: {
    allProductSelected() {
      return this.item.allProduct;
    },
    allVarietySelected() {
      return this.item.allVariety
    },
    availableTabs() {
      // Tabs
      let tabs = this.tabs ?? [];

      if (this.item && this.item.type == "Import") {
        tabs = tabs.filter((x) => x != "Production Areas");
      }

      if (
        this.item.locationDocumentSubtypes &&
        this.item.locationDocumentSubtypes.length > 0
      ) {
        tabs = tabs.filter(
          (x) => x != "Production Areas" && x != "Countries" && x != "Products" && x != 'Attest Documents'
        );
      }

      return tabs;
    },
    filteredIntegrationOptions() {
      let result = this.integrationOptions
      console.log(this.integrationOptions)
      if (this.item.integrationType && this.item.integrationType == 'restapi') {
        result = result.filter(x => x.type == 'Integration' || x.type =='Event' || x.type == 'Login')
      } else if (this.item.integrationType && this.item.integrationType == 'manual') {
        result = result.filter(x => x.type == 'Document')
      }
      return result
    },
    filterCountries() {
      let result = [];
      let regions = this.regions;
      if (this.filterRegion) {
        regions = regions.filter((x) => x.id == this.filterRegion);
      }
      result = [].concat.apply(
        [],
        regions.map((x) => x.countryRegions)
      );
      result.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
      if (this.searchCountry) {
        result = result.filter((x) =>
          x.name.toLowerCase().includes(this.searchCountry.toLowerCase())
        );
      }
      if (this.showOnlySelectedCountry && !this.item.allCountry) {
        result = result.filter((x) => this.selectedCountries.includes(x.id));
      }
      return result;
    },
    filterCountryAttest() {
      let result = [];
      let regions = this.regions;
      result = [].concat.apply(
        [],
        regions.map((x) => x.countryRegions)
      );
      result.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
      if (this.searchCountryAttest) {
        result = result.filter((x) =>
          x.name.toLowerCase().includes(this.searchCountryAttest.toLowerCase())
        );
      }
      if (this.showOnlySelectedCountryAttest && !this.item.allCountryAttest) {
        result = result.filter((x) => this.selectedCountryAttest.includes(x.id));
      }
      return result;
    },
    filterProducts() {
      let result = this.products.data.filter((x) => x.type == "Product");
      if (this.showOnlySelectedProduct && !this.item.allProduct) {
        result = result.filter((x) => this.selectedProducts.includes(x.id));
      }
      if (this.productFilter) {
        result = result.
          filter((x) => x.productGroupId == this.productFilter);
      }
      if (this.searchProduct) {
        result = result.filter(
          (x) =>
            x.name.toLowerCase().includes(this.searchProduct.toLowerCase()) ||
            (x.hsCode && x.hsCode.alternateCode.includes(this.searchProduct))
        );
      }
      return result;
    },
    filterProductGroups() {
      let result = this.products.data.filter((x) => x.type == "Product Group");
      if (this.showOnlySelectedProductGroup && !this.item.allProductGroup) {
        result = result.filter((x) => this.selectedProductGroups.includes(x.id));
      }
      if (this.searchProductGroup) {
        result = result.filter(
          (x) =>
            x.name
              .toLowerCase()
              .includes(this.searchProductGroup.toLowerCase()) ||
            (x.hsCode &&
              x.hsCode.alternateCode.includes(this.searchProductGroup))
        );
      }
      return result;
    },
    filterVarieties() {
      let result = this.products.data.filter((x) => x.type == "Variety");

      if (this.varietyFilter) {
        result = result.filter((x) => x.productId == this.varietyFilter);
      }
      if (this.showOnlySelectedVariety && !this.item.allVariety) {
        result = result.filter((x) => this.selectedVarieties.includes(x.id));
      }
      if (this.searchVariety) {
        result = result.filter(
          (x) =>
            x.name.toLowerCase().includes(this.searchVariety.toLowerCase()) ||
            (x.hsCode && x.hsCode.alternateCode.includes(this.searchVariety))
        );
      }
      return result;
    },
    filterRegions() {
      let result = this.regions;
      if (this.showOnlySelectedRegion && !this.item.allRegion) {
        result = result.filter((x) => this.selectedRegions.includes(x.id));
      }
      if (this.searchRegion) {
        result = result.filter((x) =>
          x.name.toLowerCase().includes(this.searchRegion.toLowerCase())
        );
      }
      return result;
    },
    selectedCode() {
      let result = null
      if (this.item.integrationType && this.item.integrationType == 'restapi') {
        result = this.item.integrationCode ?? this.item.eventCode
      } else if (this.item.integrationType && this.item.integrationType == 'manual') {
        result = this.item.documentationCode
      }
      return result
    }
  },
  created(){
    this.getTaskTypes()
  },
  methods: {
    addFiles() {
      this.$refs["uploader_" + this.index].$el.children[0].click();
    },
    addLabel() {
      this.supportingDocumentItem.locationSupportingDocumentLabels.push({
        documentLabel: this.documentLabel,
      });
      this.documentLabel = {};
      this.labelList++;
      this.$nextTick(() =>
        this.$nextTick(() => this.$refs["new_label"][0].focus())
      );
    },
    async addPointOfEntry(port) {
      this.specificPort = null;
      let obj = {
        name: port.name,
        locode: port.locode,
        unLocodeId: port.id,
      };
      if (this.item.id) {
        obj.locationDocumentId = this.item.id;
        obj = await this.$API.updateLocationDocumentPoint([obj]);
        this.item.locationDocumentPoints = [
          ...this.item.locationDocumentPoints,
          ...obj,
        ];
      } else {
        this.item.locationDocumentPoints.push(obj);
      }
      this.pointOfEntryKey++;
    },
    addSupportingDocument() {
      this.modalType = "Add";
      this.supportingDocumentItem = {};
      this.supportingDocumentModal = true;
      // this.selectedSupportingDocument = {
      //   name: null,
      //   locationSupportingDocumentLabels: [],
      // };
      // this.supportingDocumentKey++;
      // this.$nextTick(() =>
      //   this.$nextTick(() => this.$refs["supportingDocumentName"].focus())
      // );
    },
    addSupportingDocumentToList() {
      this.supportingDocumentItem.name = this.supportingDocumentSearch;
      this.supportingDocumentList.push({
        name: this.supportingDocumentItem.name,
        locationSupportingDocumentLabels:
          this.supportingDocumentItem.locationSupportingDocumentLabels,
      });
      this.selectedSupportingDocument = {
        name: this.supportingDocumentItem.name,
        locationSupportingDocumentLabels:
          this.supportingDocumentItem.locationSupportingDocumentLabels,
      };
      this.supportingDocumentKey++;
    },
    close() {
      this.selectedTab = "Info";
      this.tabKey++;
      this.selectedProducts = [];
      this.selectedProductGroups = [];
      this.supportingDocumentItem = {};
      this.selectedSupportingDocument = {};
      this.selectedProductGroup = {};
      this.$emit("close");
    },
    createDocumentSubtype() {
      this.subtypeItem = {
        type: this.item.type,
        modalType: "subtype",
        countryRegionId: this.country ? this.country.id : null,
        regionId: this.region ? this.region.id : null,
        integrationType: "manual",
        quantityType: "Shipment",
        submissionType: "Digital",
        version: 1,
        fileList: [],
        locationSupportingDocuments: [],
        locationDocumentSubdivisions: [],
        locationDocumentProducts: [],
        locationDocumentAreas: [],
        locationDocumentSubtypes: [],
        locationDocumentAttests: [],
        countryMode: "Include",
        regionMode: "Include",
        productGroupMode: "Include",
        productMode: "Include",
        varietyMode: "Include",

        pointOfEntryMode: "Exclude",
        allCountry: false,
        allRegion: false,
        allProductGroup: false,
        allProduct: false,
        allVariety: false,

        shipper: false,
        forwarder: false,
        consignee: false,
        buyer: false,
        notifyParty: false,

        parentTypeDocumentId: this.item.id ?? null,
        regulatoryBody: this.item.regulatoryBody,
        regulatoryBodyId: this.item.regulatoryBody
          ? this.item.regulatoryBody.id
          : null,
        rank: this.item.locationDocumentSubtypes.length + 1,
        index: this.item.locationDocumentSubtypes.length,
        submittedRule: 1,
        submittedRuleType: 'Before',
        submittedRuleEvent: 'etd',
        completedRule: 1,
        completedRuleType: 'Before',
        completedRuleEvent: 'etd',

      };
      this.subtypeModal = true;
    },
    async createSupportingDocument() {
      if (this.item.id) {
        if (this.supportingDocumentItem.id) {
          this.supportingDocumentItem.name =
            this.selectedSupportingDocument.name;
          await this.$API.updateSupportingDocument(this.supportingDocumentItem);
          let index = this.item.locationSupportingDocuments.find(
            (x) => x.id == this.supportingDocumentItem.id
          );
          this.item.locationSupportingDocuments[index] =
            this.supportingDocumentItem;
        } else {
          this.supportingDocumentItem.name =
            this.selectedSupportingDocument.name;
          this.supportingDocumentItem.locationDocumentId = this.item.id;
          let result = await this.$API.createSupportingDocument(
            this.supportingDocumentItem
          );
          this.supportingDocumentItem = result;
          this.item.locationSupportingDocuments.push(result);
        }
      } else {
        this.supportingDocumentItem.name = this.selectedSupportingDocument.name;
        this.item.locationSupportingDocuments.push(this.supportingDocumentItem);
      }
      this.supportingDocumentItem.edit = false;
      this.supportingDocumentKey++;
      this.editSupportingDocument(this.supportingDocumentItem);
    },
    deleteDocumentSubtype(item, index) {
      this.$confirm(
        "Are you sure you want to delete this document?",
        "Confirm",
        {
          center: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          type: "plain",
        }
      )
        .then(async () => {
          if (item.id) {
            await this.$API.updateLocationDocument({
              id: item.id,
              isActive: false,
              isDeleted: true,
            });
          }
          this.item.locationDocumentSubtypes.splice(index, 1);
          this.$message({
            type: "success",
            message: "Successfully deleted!",
          });
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "Delete canceled",
          // });
        });
    },
    async downloadDocument(doc) {
      let file = await this.$API.downloadFile({
        key: doc.file,
      });

      doc.file = file.file;
      doc.fileType = file.fileType;
      var hiddenElement = document.createElement("a");
      hiddenElement.href = file.file;
      hiddenElement.target = "_blank";
      hiddenElement.download = doc.fileName;
      hiddenElement.click();
    },
    editDocumentSubtype(item, index) {
      item.index = index;
      this.subtypeItem = item;
      this.subtypeItem.modalType = "subtype";
      this.subtypeItem.fileList = [];
      this.subtypeModal = true;
    },
    editSupportingDocument(item) {
      this.supportingDocumentItem = item;
      this.supportingDocumentItem.edit = false;
      this.modalType = "Manage";
      this.supportingDocumentList.push({
        name: this.supportingDocumentItem.name,
        locationSupportingDocumentLabels:
          this.supportingDocumentItem.locationSupportingDocumentLabels,
      });
      this.selectedSupportingDocument = {
        name: this.supportingDocumentItem.name,
        locationSupportingDocumentLabels:
          this.supportingDocumentItem.locationSupportingDocumentLabels,
      };
      this.supportingDocumentKey++;
    },
    isTabSelected(tab) {
      return this.selectedTab == tab;
    },
    async removeSupportingDocument(item) {
      this.$confirm(
        `Are you sure you want to remove this document?`,
        "Confirm",
        {
          center: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          type: "plain",
        }
      )
        .then(async () => {
          await this.$API.updateSupportingDocument({
            id: item.id,
            isActive: false,
            isDeleted: true,
          });
          let index = this.item.locationSupportingDocuments.indexOf(
            this.item.locationSupportingDocuments.find((x) => x.id == item.id)
          );
          if (index > -1)
            this.item.locationSupportingDocuments.splice(index, 1);

          if (
            this.item.locationSupportingDocuments &&
            this.item.locationSupportingDocuments.length > 0
          ) {
            this.editSupportingDocument(
              this.item.locationSupportingDocuments[0]
            );
          } else {
            this.addSupportingDocument();
          }

          this.supportingDocumentKey++;
          this.$message({
            type: "success",
            message: "Successfully deleted!",
          });
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "Delete canceled",
          // });
        });
    },
    getIcon(name) {
      let type = name.split(".");
      let result = null;
      if (type && type.length > 0) {
        result = type.at(-1);
      }
      if (result == "xlsx") {
        result = "xls";
      }
      return `/icons/${result}.svg`;
    },
    productGroupSelected(id) {
      return this.selectedProductGroups.includes(id) || (this.item.allProductGroup && this.item.productGroupMode == 'Include');
    },
    productSelected(id) {
      return this.selectedProducts.includes(id) || (this.item.allProduct && this.item.productMode == 'Include');
    },
    regionSelected(code) {
      let find = this.regions.find((x) => x.code == code);
      return find ? this.selectedRegions.includes(find.id) : false;
    },
    async processDocumentAttests(item, createDocument) {
      // Product Groups
      let filtered = item.locationDocumentAttests.filter((x) => !x.id);
      if (item.parentTypeDocumentId && filtered.length > 0) {
        filtered.forEach((x) => {
          x.locationDocumentId = item.id;
        });
        let result = await this.$API.updateLocationDocumentAttest(filtered);
        item.locationDocumentAttests = result;
      } else {
        let create = this.selectedCountryAttest
          .filter(
            (x) =>
              !item.locationDocumentAttests
                .filter((y) => y.countryRegionId)
                .map((y) => y.countryRegionId)
                .includes(x)
          )
          .map((x) => ({ countryRegionId: x, locationDocumentId: item.id }));

        let remove = item.locationDocumentAttests
          .filter(
            (x) =>
              x.id &&
              x.countryRegionId &&
              !this.selectedCountryAttest.includes(x.countryRegionId)
          )
          .map((x) => ({ id: x.id, isActive: false, isDeleted: true }));

        let data = [...create, ...remove];
        if (createDocument && data.length > 0) {
          let result = await this.$API.updateLocationDocumentAttest(data);
          item.locationDocumentAttests = result;
          // this.$emit("update", item);
        }
        return data;
      }
    },
    async processDocumentAreas(item, createDocument) {
      // Product Groups
      let filtered = item.locationDocumentAreas.filter((x) => !x.id);

      if (item.parentTypeDocumentId && filtered.length > 0) {
        filtered.forEach((x) => {
          x.locationDocumentId = item.id;
        });
        let result = await this.$API.updateLocationDocumentArea(filtered);
        item.locationDocumentAreas = result;
      } else {
        let createGroups = this.selectedRegions
          .filter(
            (x) =>
              !item.locationDocumentAreas
                .filter((y) => y.regionId)
                .map((y) => y.regionId)
                .includes(x)
          )
          .map((x) => ({ regionId: x, locationDocumentId: item.id }));

        let removeGroups = item.locationDocumentAreas
          .filter(
            (x) => x.regionId && !this.selectedRegions.includes(x.regionId)
          )
          .map((x) => ({ id: x.id, isActive: false, isDeleted: true }));

        // Products
        let create = this.selectedCountries
          .filter(
            (x) =>
              !item.locationDocumentAreas
                .filter((y) => y.countryRegionId)
                .map((y) => y.countryRegionId)
                .includes(x)
          )
          .map((x) => ({ countryRegionId: x, locationDocumentId: item.id }));

        let remove = item.locationDocumentAreas
          .filter(
            (x) =>
              x.id &&
              x.countryRegionId &&
              !this.selectedCountries.includes(x.countryRegionId)
          )
          .map((x) => ({ id: x.id, isActive: false, isDeleted: true }));

        let data = [...createGroups, ...create, ...removeGroups, ...remove];
        if (createDocument && data.length > 0) {
          let result = await this.$API.updateLocationDocumentArea(data);
          item.locationDocumentAreas = result;
          // this.$emit("update", item);
        }
        return data;
      }
    },
    async attachUpload(file) {
      if (file) {
        let url = await URL.createObjectURL(file);
        this.toDataUrl(url, (data) => {
          this.item.fileUpload = {
            name: file.name,
            type: file.type,
            data,
          };
          this.fileKey++;
        });
      }
    },
    async getTaskTypes() {
      let result = await this.$API.getTaskTypes();
      this.taskTypes = result;
    },
    async processSubdivision(item, createDocument) {
      let filtered = item.locationDocumentSubdivisions.filter((x) => !x.id);
      if (item.id && filtered.length > 0) {
        filtered.forEach((x) => {
          x.locationDocumentId = item.id;
        });
        let result = await this.$API.updateLocationDocumentSubdivision(
          filtered
        );
        item.locationDocumentSubdivisions = result;
      } else {
        let create = this.selectedAreas.filter(
          (x) =>
            !item.locationDocumentSubdivisions
              .map((y) => y.countrySubdivisionId)
              .includes(x.id)
        );
        let remove = item.locationDocumentSubdivisions.filter(
          (x) =>
            !this.selectedAreas
              .map((y) => y.id)
              .includes(x.countrySubdivisionId)
        );

        if (create.length > 0) {
          create = create.map((x) => ({
            countrySubdivisionId: x.id,
            locationDocumentId: item.id,
          }));
        }

        if (remove.length > 0) {
          remove = remove
            .filter((x) => x.id)
            .map((x) => ({
              id: x.id,
              isActive: false,
              isDeleted: true,
            }));
        }
        let data = [...create, ...remove];
        if (createDocument && data.length > 0) {
          let result = await this.$API.updateLocationDocumentSubdivision(data);
          item.locationDocumentSubdivisions = result;
          // this.$emit("update", item);
        }

        return data;
      }
    },
    async processDocumentProducts(item, createDocument) {
      let filtered = item.locationDocumentProducts.filter((x) => !x.id);
      if (item.parentTypeDocumentId && filtered.length > 0) {
        filtered.forEach((x) => {
          x.locationDocumentId = item.id;
        });
        let result = await this.$API.updateLocationDocumentProduct(filtered);
        item.locationDocumentProducts = result;
      } else {
        // Product Groups
        let createGroups = this.selectedProductGroups
          .filter(
            (x) =>
              !item.locationDocumentProducts
                .filter((y) => y.productGroupId)
                .map((y) => y.productGroupId)
                .includes(x)
          )
          .map((x) => ({ productGroupId: x, locationDocumentId: item.id }));

        let removeGroups = item.locationDocumentProducts
          .filter(
            (x) =>
              x.productGroupId &&
              !this.selectedProductGroups.includes(x.productGroupId)
          )
          .map((x) => ({ id: x.id, isActive: false, isDeleted: true }));

        // Products
        let create = this.selectedProducts
          .filter(
            (x) =>
              !item.locationDocumentProducts
                .filter((y) => y.productId)
                .map((y) => y.productId)
                .includes(x)
          )
          .map((x) => ({ productId: x, locationDocumentId: item.id }));

        let remove = item.locationDocumentProducts
          .filter(
            (x) => x.productId && !this.selectedProducts.includes(x.productId)
          )
          .map((x) => ({ id: x.id, isActive: false, isDeleted: true }));

        // Varieties
        let createVariety = this.selectedVarieties
          .filter(
            (x) =>
              !item.locationDocumentProducts
                .filter((y) => y.varietyId)
                .map((y) => y.varietyId)
                .includes(x)
          )
          .map((x) => ({ varietyId: x, locationDocumentId: item.id }));

        let removeVarieties = item.locationDocumentProducts
          .filter(
            (x) => x.varietyId && !this.selectedVarieties.includes(x.varietyId)
          )
          .map((x) => ({ id: x.id, isActive: false, isDeleted: true }));

        let data = [...createGroups, ...create, ...removeGroups, ...remove, ...createVariety, ...removeVarieties];
        if (createDocument && data.length > 0) {
          let result = await this.$API.updateLocationDocumentProduct(data);
          item.locationDocumentProducts = result;
        }
        return data;
      }
    },
    async removeLabel(index) {
      let item =
        this.supportingDocumentItem.locationSupportingDocumentLabels[index];
      if (!item.id) {
        this.supportingDocumentItem.locationSupportingDocumentLabels.splice(
          index,
          1
        );
      } else {
        await this.$API.updateSupportingDocumentLabel({
          id: item.id,
          isActive: true,
          isDeleted: false,
        });
        this.supportingDocumentItem.locationSupportingDocumentLabels.splice(
          index,
          1
        );
      }
    },
    async saveDocument(item) {
      this.savingDocument = true;
      item.regulatoryBodyId = item.regulatoryBody.id;
      if (item.id) {
        await this.$API.updateLocationDocument(item);
      } else {
        if (this.type == "subtype") {
          if (item.parentTypeDocumentId) {
            delete item.id;
            let result = await this.$API.createLocationDocument(item);
            item.id = result.id;
          }
        } else {
          let result = await this.$API.createLocationDocument(item);
          item.id = result.id;
        }
      }
      if (
        item.locationSupportingDocuments &&
        item.locationSupportingDocuments.length > 0
      ) {
        item.locationSupportingDocuments.forEach((x) => {
          x.locationDocumentId = item.id;
        });
        for (
          let i = 0;
          i < item.locationSupportingDocuments.filter((x) => !x.id).length;
          i++
        ) {
          await this.$API.createSupportingLocationDocument(
            item.locationSupportingDocuments[i]
          );
        }
      }

      let areas = await this.processDocumentAreas(item, item.id ? true : false);
      let attests = await this.processDocumentAttests(item, item.id ? true : false);
      let products = await this.processDocumentProducts(
        item,
        item.id ? true : false
      );
      let subdivisions = await this.processSubdivision(
        item,
        item.id ? true : false
      );
      if (!item.id) {
        item.locationDocumentAreas = areas;
        item.locationDocumentProducts = products;
        item.locationDocumentSubdivisions = subdivisions;
        item.locationDocumentAttests = attests
      }
      if (
        item.locationDocumentSubtypes &&
        item.locationDocumentSubtypes.length > 0
      ) {
        let newSubtypes = item.locationDocumentSubtypes.filter((x) => !x.id);
        for (let i = 0; i < newSubtypes.length; i++) {
          newSubtypes[i].type = item.type;
          newSubtypes[i].parentTypeDocumentId = item.id;
          await this.saveDocument(newSubtypes[i]);
        }
      }
      if (item.id == this.item.id) {
        this.$emit("update", item);
        this.$emit("close");
        item = {};
        this.$message.success("Successfully submitted!");
        this.savingDocument = false;
      }
    },
    selectAllLocations() {
      if (
        this.selectedAreas &&
        this.subdivisions &&
        this.selectedAreas.length < this.subdivisions.length
      ) {
        this.selectedAreas = this.subdivisions;
      } else {
        this.selectedAreas = [];
      }
    },
    selectAllProducts() {
      if (
        this.selectedProducts &&
        this.products &&
        this.selectedProducts.length < this.products.total
      ) {
        this.selectedProducts = this.products.data.map((x) => x.id);
      } else {
        this.selectedProducts = [];
      }
    },
    removeGroup(id) {
      this.selectedProductGroups.splice(
        this.selectedProductGroups.indexOf(id),
        1
      );
    },
    removeRegion(id) {
      this.selectedRegions.splice(this.selectedRegions.indexOf(id), 1);
    },
    removeRegionCountries(region) {
      this.selectedCountries = this.selectedCountries.filter(
        (x) => !region.countryRegions.map((y) => y.id).includes(x)
      );
    },
    removeGroupProducts() {
      this.selectedProducts = this.selectedProducts.filter(
        (x) => !this.products.data.map((y) => y.id).includes(x)
      );
    },
    async removePointOfEntry(item) {
      if (item.id) {
        await this.$API.updateLocationDocumentPoint([
          {
            id: item.id,
            isActive: false,
            isDeleted: true,
          },
        ]);
      }
      this.item.locationDocumentPoints.splice(
        this.item.locationDocumentPoints.indexOf(item),
        1
      );
      this.pointOfEntryKey++;
    },
    async removeSupportingDoc(item, index) {
      if (item.id) {
        await this.$API.updateSupportingLocationDocument({
          id: item.id,
          isActive: false,
          isDeleted: true,
        });
      }
      this.item.locationSupportingDocuments.splice(index, 1);
      this.supportingDocumentKey++;
    },
    async saveLocationSupportingDocument() {
      if (this.item.id) {
        let result = await this.$API.createSupportingLocationDocument({
          locationDocumentId: this.item.id,
          supportingDocumentId: this.supportingDocumentItem.id,
        });
        let obj = result;
        obj.supportingDocument = this.supportingDocumentItem;
        this.item.locationSupportingDocuments.push(obj);
      } else {
        this.item.locationSupportingDocuments.push({
          supportingDocument: this.supportingDocumentItem,
          supportingDocumentId: this.supportingDocumentItem.id,
        });
      }

      this.$message.success("Successfully added!");
      this.supportingDocumentModal = false;
      this.supportingDocumentItem = {};
      this.supportingDocumentKey++;
    },
    setCode(method){
      if (this.item.integrationType && this.item.integrationType == 'restapi') {
        if(method.tag == 'Event'){
          this.item.integrationCode = null
          this.item.documentationCode = null
          this.item.eventCode = method.integrationCode;
        } else {
          this.item.integrationCode = method.integrationCode;
          this.item.documentationCode = null
          this.item.eventCode = null
        }
      } else if (this.item.integrationType && this.item.integrationType == 'manual') {
        this.item.documentationCode = method.documentationCode;
        this.item.integrationCode = null
        this.item.eventCode = null
      }
      console.log(this.item)
      this.selectCodeModal = false
    },
    async submitUpload(obj) {
      // let url = "http://localhost:3000";
      // if (process.env.NODE_ENV == "production") {
      //   url = "https://api.loglive.io";
      // }
      let Api = axios.create({
        baseURL: process.env.NODE_ENV == 'production' ? 'https://api.loglive.ai' : process.env.NODE_ENV == 'staging' ? 'https://api.loglive.ai': 'http://localhost:3000',
        headers: {
          Authorization: `${this.$store.state.token}`,
          apikey: `${this.$store.state.orgToken}`,
        },
      });
      let result = await Api.post("/locationDocument", obj, {
        onUploadProgress: (progressEvent) => {
          obj.fileList[obj.file.fileIndex].progress = Math.ceil(
            (progressEvent.loaded / progressEvent.total) * 100
          );
        },
      });
      result = result.data;
      this.$emit("updateFile", {
        id: result.id,
        file: result.file,
        fileName: result.fileName,
      });
      obj.fileList = [];
      this.submittingVersion = false;
    },
    async submitVersionUpload(obj) {
      // let url = "http://localhost:3000";
      // if (process.env.NODE_ENV == "production") {
      //   url = "https://api.loglive.io";
      // }
      let Api = axios.create({
        baseURL: process.env.NODE_ENV == 'production' ? 'https://api.loglive.ai' : process.env.NODE_ENV == 'staging' ? 'https://api.loglive.ai': 'http://localhost:3000',
        headers: {
          Authorization: `${this.$store.state.token}`,
          apikey: `${this.$store.state.orgToken}`,
        },
      });
      obj.parentDocumentId = obj.id;
      delete obj.id;
      let result = await Api.post("/locationDocument/version", obj, {
        onUploadProgress: (progressEvent) => {
          obj.fileList[obj.file.fileIndex].progress = Math.ceil(
            (progressEvent.loaded / progressEvent.total) * 100
          );
        },
      });

      result = result.data;
      (result.replacementId = result.parentDocumentId),
        this.$emit("newVersion", result);
      obj.fileUpload = null;
      this.submittingVersion = false;
      this.versionModal = false;
    },
    async submitVersion() {
      this.submittingVersion = true;
      this.item.fileName = this.item.fileUpload.name;
      this.submitVersionUpload(this.item);
    },
    toDataUrl(url, callback) {
      var xhr = new XMLHttpRequest();
      xhr.onload = function () {
        var reader = new FileReader();
        reader.onloadend = function () {
          callback(reader.result);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.send();
    },
    async updatedDocument(item) {
      console.log("Updated", item);
      if (item.modalType) {
        if (item.index >= 0 || item.id) {
          if (item.id) {
            let index = this.item.locationDocumentSubtypes.findIndex(
              (x) => x.id == item.id
            );
            if (index > -1) {
              this.item.locationDocumentSubtypes[index] = item;
            } else {
              this.item.locationDocumentSubtypes.push(item);
            }
          } else {
            this.item.locationDocumentSubtypes[item.index] = item;
          }
        } else {
          this.item.locationDocumentSubtypes.push(item);
        }
        console.log(this.item.locationDocumentSubtypes);
        this.tabKey++;
        this.subtypeModal = false;
        this.subtypeItem = {};
      }
    },
    fileUpdated(item) {
      let find = this.locationDocumentSubtypes.find((x) => x.id == item.id);
      let index = this.locationDocumentSubtypes.indexOf(find);
      this.locationDocumentSubtypes[index].file = item.file;
      this.locationDocumentSubtypes[index].fileName = item.fileName;
      this.documentVersionModal = false;
      this.document = {};
    },
    updateSubtypeRanks() {
      this.item.locationDocumentSubtypes.forEach(async (x, index) => {
        x.rank = index + 1;
        if (x.id) {
          await this.$API.updateLocationDocument({ id: x.id, rank: x.rank });
        }
      });
    },
  },
};
</script>
